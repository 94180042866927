import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Дослідіть RevUp Auto Care
			</title>
			<meta name={"description"} content={"Кожна миля має значення"} />
			<meta property={"og:title"} content={"Про нас | Дослідіть RevUp Auto Care"} />
			<meta property={"og:description"} content={"Кожна миля має значення"} />
			<meta property={"og:image"} content={"https://novirexus.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://novirexus.com/img/car.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://novirexus.com/img/car.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://novirexus.com/img/car.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://novirexus.com/img/car.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://novirexus.com/img/car.png"} />
			<meta name={"msapplication-TileImage"} content={"https://novirexus.com/img/car.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://novirexus.com/img/4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Про нас 
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
				RevUp Auto Care було створено з простою місією: надавати виняткові послуги з догляду за автомобілем, пристосовані до потреб кожного клієнта. Починаючи зі скромного гаража, ми перетворилися на провідну станцію технічного обслуговування завдяки нашій прихильності до якості та задоволеності клієнтів. Наш шлях був продиктований пристрастю до автомобільної досконалості та бажанням запропонувати сервіс, який відчувається як особистий та справжній.
				</Text>
				<Link
					href="/contact"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-orange"
				>
					Звʼязатися з нами
				</Link>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Наші досвідчені механіки
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Серцем RevUp Auto Care є наша команда експертів-механіків. Кожен механік відібраний за його досвід, пристрасть до автомобілів та відданість сервісу. Наша команда постійно навчається новітнім автомобільним технологіям, щоб гарантувати, що ми зможемо впоратися з будь-яким викликом, який може постати перед вашим автомобілем. Таке прагнення до професійного зростання означає, що ваш автомобіль завжди в надійних руках.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://novirexus.com/img/5.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://novirexus.com/img/6.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Що нас відрізняє?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Прихильність до якості: Ми використовуємо лише найякісніші деталі та інструменти, щоб забезпечити найкраще обслуговування вашого автомобіля.
					<br/><br/>
Увага до деталей: До кожного автомобіля ми ставимося, як до власного, з ретельним доглядом і точністю.
					<br/><br/>
Екологічно чисті практики: Ми прагнемо зменшити наш вплив на навколишнє середовище за допомогою сталих практик.
					<br/><br/>
Клієнтоорієнтований підхід: Ми адаптуємо наші послуги до ваших конкретних автомобільних потреб і гарантуємо, що ви будете проінформовані на кожному кроці.

				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="p" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
			Приєднуйтесь до сім'ї RevUp
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			У RevUp Auto Care ми не просто обслуговуємо автомобілі - ми створюємо довготривалі відносини. Обирайте нас, і нехай ваш досвід догляду за автомобілем стане чимось більшим, ніж просто технічне обслуговування - нехай це буде подорож, сповнена довіри та задоволеності. З нами ви не просто ще один клієнт - ви цінний член сім'ї RevUp.
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});